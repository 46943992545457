import React, { useState, useEffect, useRef } from 'react';
import { 
  Code2, Smartphone, Terminal, ArrowRight, 
  Boxes, Circle, Globe, Zap, Server, Cloud,
  Shield, Rocket, Database, Monitor, Settings
} from 'lucide-react';
import { Link } from 'react-router-dom';

const NumberCounter = ({ end, duration = 2000 }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => entry.isIntersecting && setIsVisible(true),
      { threshold: 0.1 }
    );
    if (counterRef.current) observer.observe(counterRef.current);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (!isVisible) return;
    let startTime;
    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      setCount(Math.floor(progress * end));
      if (progress < 1) requestAnimationFrame(animate);
    };
    requestAnimationFrame(animate);
  }, [end, duration, isVisible]);

  return <span ref={counterRef}>{count}</span>;
};

const KeywordBubble = ({ children, className = "" }) => (
  <span className={`inline-block px-4 py-2 rounded-full text-sm font-semibold tracking-wide
                    bg-gradient-to-r from-blue-50 to-blue-100 dark:from-blue-900/40 dark:to-blue-800/40
                    text-blue-700 dark:text-blue-200 shadow-sm
                    transition-all duration-300 hover:scale-102 hover:shadow-sm ${className}`}>
    {children}
  </span>
);

const ServiceCard = ({ title, icon: Icon, path, keywords, features, stats, secondaryIcons, index }) => {
  const handleContactClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const contactSection = document.getElementById("Contact");
    if (contactSection) {
      const headerOffset = 100;
      const elementPosition = contactSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  // Service-specific color mapping based on index
  const colorSchemes = [
    {
      gradient: 'from-pink-500 to-rose-600',
      accent: 'text-pink-500',
      hover: 'hover:bg-pink-50 dark:hover:bg-pink-900/30',
      button: 'from-pink-500 to-rose-600',
      border: 'border-pink-500 dark:border-pink-400',
      iconBg: 'from-pink-500/20 to-rose-600/20'
    },
    {
      gradient: 'from-violet-500 to-purple-600',
      accent: 'text-violet-500',
      hover: 'hover:bg-violet-50 dark:hover:bg-violet-900/30',
      button: 'from-violet-500 to-purple-600',
      border: 'border-violet-500 dark:border-violet-400',
      iconBg: 'from-violet-500/20 to-purple-600/20'
    },
    {
      gradient: 'from-blue-500 to-cyan-600',
      accent: 'text-blue-500',
      hover: 'hover:bg-blue-50 dark:hover:bg-blue-900/30',
      button: 'from-blue-500 to-cyan-600',
      border: 'border-blue-500 dark:border-blue-400',
      iconBg: 'from-blue-500/20 to-cyan-600/20'
    }
  ];

  const colors = colorSchemes[index % colorSchemes.length];
  const [isHovered, setIsHovered] = useState(false);
  
  return (
    <div 
      className="group relative p-8 bg-white/90 dark:bg-gray-800/90 backdrop-blur-xl rounded-2xl 
                 shadow-md transition-all duration-300 hover:shadow-lg cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Animated gradient border */}
      <div className={`absolute inset-0 rounded-2xl bg-gradient-to-r ${colors.gradient} opacity-0 
                    group-hover:opacity-30 transition-opacity duration-300 -z-10 blur-sm`}></div>

      {/* Floating secondary icons */}
      <div className="absolute -right-4 -top-4 w-32 h-32">
        {secondaryIcons.map((SecIcon, index) => (
          <SecIcon 
            key={index}
            className={`absolute w-6 h-6 text-blue-400/20 transition-all duration-700
                       ${index === 0 ? 'right-8 top-8' : index === 1 ? 'right-4 top-12' : 'right-12 top-4'}
                       ${isHovered ? 'translate-x-2 -translate-y-2 rotate-12' : ''}`}
          />
        ))}
      </div>

      {/* Main icon with animated background */}
      <div className="relative w-24 h-24 mb-8">
        <div className={`absolute inset-0 bg-gradient-to-r ${colors.iconBg}
                        rounded-2xl blur-lg transition-all duration-500 ${isHovered ? 'scale-110' : ''}`}></div>
        <div className="relative flex items-center justify-center w-full h-full 
                      bg-white dark:bg-gray-800 rounded-2xl group-hover:rotate-3 transition-transform duration-500">
          <Icon className={`w-12 h-12 ${colors.accent} transition-transform duration-500 
                         group-hover:scale-110`} />
        </div>
      </div>

      {/* Title and Keywords */}
      <h3 className="text-3xl font-bold text-gray-900 dark:text-white mb-6">{title}</h3>
      
      {/* Animated keyword grid */}
      <div className="grid grid-cols-2 gap-3 mb-8">
        {keywords.map((keyword, index) => (
          <KeywordBubble 
            key={index}
            className={`transform transition-all duration-500 ${isHovered ? 'translate-y-1' : ''}`}
            style={{ transitionDelay: `${index * 50}ms` }}
          >
            {keyword}
          </KeywordBubble>
        ))}
      </div>

      {/* Feature icons */}
      <div className="grid grid-cols-3 gap-4 mb-8">
        {features.map(({ icon: FeatureIcon, label }, index) => (
          <div key={index} className="text-center group/feature">
            <div className="flex justify-center mb-2">
              <FeatureIcon className="w-6 h-6 text-blue-500 dark:text-blue-400 
                                    transition-transform duration-300 group-hover/feature:scale-110" />
            </div>
            <span className="text-xs text-gray-600 dark:text-gray-400">{label}</span>
          </div>
        ))}
      </div>

      {/* Stats with animated background */}
      <div className="grid grid-cols-2 gap-4 mb-8">
        {stats.map((stat, index) => (
          <div key={index} className="relative group/stat">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-600/5 to-purple-600/5 
                          rounded-xl transition-all duration-300 group-hover/stat:scale-105"></div>
            <div className="relative p-4 text-center">
              <div className="text-3xl font-bold text-blue-600 dark:text-blue-400 mb-2">
                <NumberCounter end={stat.value} />
                {stat.suffix}
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">{stat.label}</div>
            </div>
          </div>
        ))}
      </div>

      {/* Primary CTA Button */}
      <div className="flex gap-4 relative z-10">
        <Link 
          to={path}
          className={`flex-1 inline-flex items-center justify-center px-6 py-3 
                     bg-gradient-to-r ${colors.button}
                     text-white font-semibold rounded-xl shadow-lg 
                     transition-all duration-300 transform hover:-translate-y-0.5`}
        >
          <span className="mr-2">Jetzt entdecken</span>
          <ArrowRight className={`w-5 h-5 transition-all duration-500 ${isHovered ? 'translate-x-1' : ''}`} />
        </Link>
        
        <button 
          onClick={handleContactClick}
          className="inline-flex items-center justify-center px-6 py-3 
                     border-2 ${colors.border}
                     ${colors.accent} font-semibold rounded-xl
                     ${colors.hover}
                     transition-all duration-300 transform hover:-translate-y-0.5"
        >
          Kontakt
        </button>
      </div>

      {/* Make entire card clickable with overlay */}
      <Link 
        to={path}
        className="absolute inset-0 w-full h-full rounded-2xl z-0"
        aria-label={`Learn more about ${title}`}
      />
    </div>
  );
};

const Background = () => (
  <div className="absolute inset-0 overflow-hidden">
    <div className="absolute w-full h-full">
      {/* Animated gradient blobs */}
      <div className="absolute top-0 left-0 w-96 h-96 bg-blue-500/5 rounded-full blur-3xl animate-blob"></div>
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-purple-500/5 rounded-full blur-3xl animate-blob animation-delay-2000"></div>
      <div className="absolute top-1/2 left-1/2 w-96 h-96 bg-indigo-500/5 rounded-full blur-3xl animate-blob animation-delay-4000"></div>
      
      {/* Grid pattern */}
      <div className="absolute inset-0" 
           style={{
             backgroundImage: 'radial-gradient(circle at 1px 1px, rgba(100,116,139,0.05) 1px, transparent 0)',
             backgroundSize: '40px 40px'
           }}>
      </div>
    </div>
  </div>
);

const ServicesSection = () => {
  const services = [
    {
      title: "Website Design",
      icon: Code2,
      path: "/webdevelopement",
      keywords: ["React", "Next.js", "TypeScript", "Node.js"],
      features: [
        { icon: Globe, label: "SEO" },
        { icon: Zap, label: "Performance" },
        { icon: Shield, label: "Security" }
      ],
      stats: [
        { value: 95, suffix: "%", label: "Performance-Score" },
        { value: 50, suffix: "+", label: "Projekte" }
      ],
      secondaryIcons: [Monitor, Cloud, Settings]
    },
    {
      title: "App Development",
      icon: Smartphone,
      path: "/mobiledevelopement",
      keywords: ["React Native", "Flutter", "iOS", "Android"],
      features: [
        { icon: Rocket, label: "Speed" },
        { icon: Cloud, label: "Cloud" },
        { icon: Shield, label: "Security" }
      ],
      stats: [
        { value: 100, suffix: "%", label: "In-house" },
        { value: 5, suffix: "+", label: "Projekte" }
      ],
      secondaryIcons: [Cloud, Rocket, Settings]
    },
    {
      title: "Software Solutions",
      icon: Terminal,
      path: "/softwaredevelopement",
      keywords: ["Python", "Java", "Docker", "AWS"],
      features: [
        { icon: Database, label: "Scalable" },
        { icon: Server, label: "Reliable" },
        { icon: Shield, label: "Secure" }
      ],
      stats: [
        { value: 97, suffix: "%", label: "Up-Time" },
        { value: 12, suffix: "+", label: "Solutions" }
      ],
      secondaryIcons: [Server, Database, Cloud]
    }
  ];

  return (
    <section className="relative py-32 bg-gray-50 dark:bg-gray-900 overflow-hidden">
      <Background />
      
      <div className="container mx-auto px-4 lg:px-8 relative">
        {/* Section Header */}
        <div className="max-w-3xl mx-auto text-center mb-24">
          <h2 className="text-5xl md:text-6xl font-bold bg-clip-text text-transparent 
                       bg-gradient-to-r from-blue-600 to-purple-600 mb-6">
            Unser Service
          </h2>
          <div className="flex flex-wrap justify-center gap-2">
            <KeywordBubble>Innovation</KeywordBubble>
            <KeywordBubble>Quality</KeywordBubble>
            <KeywordBubble>Reliability</KeywordBubble>
          </div>
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;