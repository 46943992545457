import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Sun,
  Moon,
  Phone,
  Code2,
  Layers,
  ChevronDown,
  Terminal,
  Rocket,
  Github,
} from "lucide-react";

const Header = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Handle scroll effects
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    const root = window.document.documentElement;
    root.classList.toggle("dark", !isDarkMode);
  };

  const scrollToContact = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById("Contact");
    if (contactSection) {
      const headerOffset = 100;
      const elementPosition = contactSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
    setIsMobileMenuOpen(false);
  };

  const navItems = [
    {
      title: "Services",
      icon: <Layers className="w-4 h-4" />,
      submenu: [
        {
          title: "Web Development",
          icon: <Code2 className="w-4 h-4" />,
          path: "/webdevelopement",
        },
        {
          title: "Software Solutions",
          icon: <Terminal className="w-4 h-4" />,
          path: "/softwaredevelopement",
        },
        {
          title: "App Development",
          icon: <Rocket className="w-4 h-4" />,
          path: "/mobiledevelopement",
        },
      ],
    },
    {
      title: "Portfolio",
      path: "/Portfolio",
    },
    {
      title: "Kontakt",
      isScroll: true,
    },
  ];

  return (
    <header
      className={`fixed w-full transition-all duration-500 ${
        isScrolled
          ? `${
              isDarkMode ? "bg-gray-900/95" : "bg-white/95"
            } backdrop-blur-md shadow-lg`
          : `${isDarkMode ? "bg-gray-900" : "bg-white"}`
      } z-50`}
    >
      <div className="container mx-auto">
        <div className="flex justify-between items-center px-4 lg:px-8 py-4">
          {/* Logo */}
          <Link to="/Home" className="flex items-center space-x-2 group">
            <div className="relative">
              <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg blur opacity-25 group-hover:opacity-75 transition duration-500"></div>
              <div
                className={`relative px-4 py-2 ${
                  isDarkMode ? "bg-gray-900" : "bg-white"
                } rounded-lg leading-none flex items-center`}
              >
                <Code2
                  className={`w-8 h-8 ${
                    isDarkMode ? "text-blue-400" : "text-blue-600"
                  } transform group-hover:scale-110 transition-transform duration-300`}
                />
              </div>
            </div>
            <span
              className={`text-2xl font-bold tracking-tight ${
                isDarkMode ? "text-white" : "text-gray-900"
              } group-hover:text-blue-600 transition-colors duration-300`}
            >
              TruTec<span className="text-blue-600">.</span>
            </span>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden lg:flex items-center space-x-8">
            {navItems.map((item, index) => (
              <div key={index} className="relative group">
                {item.submenu ? (
                  <button className="flex items-center space-x-1 group">
                    <span
                      className={`font-medium ${
                        isDarkMode
                          ? "text-gray-300 hover:text-white"
                          : "text-gray-700 hover:text-gray-900"
                      } transition-colors duration-300`}
                    >
                      {item.title}
                    </span>
                    <ChevronDown className="w-4 h-4 transform group-hover:rotate-180 transition-transform duration-300" />
                  </button>
                ) : item.isScroll ? (
                  <button
                    onClick={scrollToContact}
                    className={`font-medium ${
                      isDarkMode
                        ? "text-gray-300 hover:text-white"
                        : "text-gray-700 hover:text-gray-900"
                    } transition-colors duration-300`}
                  >
                    {item.title}
                  </button>
                ) : (
                  <Link
                    to={item.path}
                    className={`font-medium ${
                      isDarkMode
                        ? "text-gray-300 hover:text-white"
                        : "text-gray-700 hover:text-gray-900"
                    } transition-colors duration-300`}
                  >
                    {item.title}
                  </Link>
                )}

                {item.submenu && (
                  <div className="absolute left-0 mt-2 w-72 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 transform -translate-y-2 group-hover:translate-y-0">
                    <div className="py-2 px-4 bg-white dark:bg-gray-800 rounded-xl shadow-xl border dark:border-gray-700">
                      {item.submenu.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subItem.path}
                          className="flex items-center space-x-3 px-4 py-3 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors duration-300"
                        >
                          <span className="text-blue-600 dark:text-blue-400">
                            {subItem.icon}
                          </span>
                          <div>
                            <p className="font-medium text-gray-900 dark:text-white">
                              {subItem.title}
                            </p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </nav>

          {/* Right Side Actions */}
          <div className="flex items-center space-x-6">
            {/* Contact - visible on desktop */}
            <a
              href="tel:+491707238166"
              className="hidden md:flex items-center space-x-2 group"
            >
              <div
                className={`p-2 rounded-full ${
                  isDarkMode ? "bg-gray-800" : "bg-gray-100"
                } group-hover:bg-blue-500 transition-colors duration-300`}
              >
                <Phone
                  className={`w-4 h-4 ${
                    isDarkMode ? "text-gray-300" : "text-gray-600"
                  } group-hover:text-white transition-colors duration-300`}
                />
              </div>
              <span
                className={`font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-700"
                } group-hover:text-blue-500 transition-colors duration-300`}
              >
                +49 170 7238166
              </span>
            </a>

            {/* Phone icon for mobile / GitHub for desktop */}
            <a
              href="tel:+491707238166"
              className={`md:hidden p-2 rounded-full ${
                isDarkMode
                  ? "bg-gray-800 hover:bg-gray-700"
                  : "bg-gray-100 hover:bg-gray-200"
              } transition-colors duration-300`}
            >
              <Phone
                className={`w-5 h-5 ${
                  isDarkMode ? "text-gray-300" : "text-gray-600"
                }`}
              />
            </a>
            <a
              href="https://github.com/FaxFox11"
              target="_blank"
              rel="noopener noreferrer"
              className={`hidden md:block p-2 rounded-full ${
                isDarkMode
                  ? "bg-gray-800 hover:bg-gray-700"
                  : "bg-gray-100 hover:bg-gray-200"
              } transition-colors duration-300`}
            >
              <Github
                className={`w-5 h-5 ${
                  isDarkMode ? "text-gray-300" : "text-gray-600"
                }`}
              />
            </a>

            {/* Dark Mode Toggle */}
            <button
              onClick={toggleDarkMode}
              className={`p-2 rounded-full focus:outline-none ${
                isDarkMode
                  ? "bg-gray-800 hover:bg-gray-700"
                  : "bg-gray-100 hover:bg-gray-200"
              } transition-all duration-300 transform hover:scale-105`}
              aria-label="Toggle dark mode"
            >
              {isDarkMode ? (
                <Sun className="w-5 h-5 text-yellow-400" />
              ) : (
                <Moon className="w-5 h-5 text-blue-600" />
              )}
            </button>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="lg:hidden p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-800 transition-colors duration-300"
            >
              <div className="w-6 h-6 flex flex-col justify-center space-y-1.5">
                <span
                  className={`block w-6 h-0.5 bg-current transform transition-all duration-300 ${
                    isMobileMenuOpen ? "rotate-45 translate-y-2" : ""
                  }`}
                ></span>
                <span
                  className={`block w-6 h-0.5 bg-current transition-all duration-300 ${
                    isMobileMenuOpen ? "opacity-0" : ""
                  }`}
                ></span>
                <span
                  className={`block w-6 h-0.5 bg-current transform transition-all duration-300 ${
                    isMobileMenuOpen ? "-rotate-45 -translate-y-2" : ""
                  }`}
                ></span>
              </div>
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`lg:hidden overflow-hidden transition-all duration-300 ${
            isMobileMenuOpen ? "max-h-96" : "max-h-0"
          }`}
        >
          <div className="px-4 py-4 space-y-4 border-t dark:border-gray-700">
            {navItems.map((item, index) => (
              <div key={index} className="space-y-2">
                {item.submenu ? (
                  <>
                    <div className="font-medium text-gray-900 dark:text-white">
                      {item.title}
                    </div>
                    <div className="pl-4 space-y-2">
                      {item.submenu.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subItem.path}
                          className="flex items-center space-x-2 text-gray-600 dark:text-gray-300 hover:text-blue-500 dark:hover:text-blue-400"
                        >
                          {subItem.icon}
                          <span>{subItem.title}</span>
                        </Link>
                      ))}
                    </div>
                  </>
                ) : item.isScroll ? (
                  <button
                    onClick={scrollToContact}
                    className="block w-full text-left font-medium text-gray-900 dark:text-white hover:text-blue-500 dark:hover:text-blue-400"
                  >
                    {item.title}
                  </button>
                ) : (
                  <Link
                    to={item.path}
                    className="block font-medium text-gray-900 dark:text-white hover:text-blue-500 dark:hover:text-blue-400"
                  >
                    {item.title}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;