import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Globe, ArrowLeft, ArrowRight, ExternalLink, Sparkles } from "lucide-react";
import musikschule from "../../assets/musikschule.png";
import greekshowcase from "../../assets/greekshowcase.jpg";
import photo from "../../assets/photo.jpg";
import tech from "../../assets/tech.png";
import yoga from "../../assets/yoga.png";
import therme from "../../assets/therme.png";
import consultancy from "../../assets/consultancy.png";

const PortfolioShowcase = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const projects = [
    {
      title: "Consultancy",
      subtitle: "Next.js • React • Typescript",
      description: "Klassische Designsprache trifft hier auf moderne Akzente",
      image: consultancy,
      stats: [
        { label: "Performance", value: "95%" },
        { label: "Accessability", value: "90%" },
        { label: "Best Practice", value: "100%" },
        { label: "SEO", value: "100%" },
      ],
      tags: ["Captured at 15. Jan. 2025, 20:04 MEZ by Google PageSpeed Insights"],
      liveUrl: "https://consulting-omega-nine.vercel.app/",
    },
    {
      title: "Yoga Studio",
      subtitle: "Vite • React • Typescript",
      description: "Eine moderne Website für ein Yoga-Studio",
      image: yoga,
      stats: [
        { label: "Performance", value: "90%" },
        { label: "Accessability", value: "73%" },
        { label: "Best Practice", value: "96%" },
        { label: "SEO", value: "91%" },
      ],
      tags: ["Captured at 09. Jan. 2025, 19:38 MEZ by Google PageSpeed Insights"],
      liveUrl: "https://vite-theta-ecru.vercel.app",
    },
{
      title: "Therme",
      subtitle: "React • Lucide-React • TailwindCSS",
      description: "Hohe Peformancewerte, modernes styling und atmospärisches Design.",
      image: therme,
      stats: [
        { label: "Performance", value: "98%" },
        { label: "Accessability", value: "75%" },
        { label: "Best Practice", value: "100%" },
        { label: "SEO", value: "100%" },
      ],
      tags: ["Captured at 10. Nov. 2025, 00:29 MEZ by Google PageSpeed Insights"],
      liveUrl: "https://therme.vercel.app/",
    },
    
    {
      title: "Griechisches Restaurant",
      subtitle: "React • Tailwind",
      description: "Hohe Peformancewerte, modernes styling und atmospärisches Design.",
      image: greekshowcase,
      stats: [
        { label: "Performance", value: "60%" },
        { label: "Accessability", value: "79%" },
        { label: "Best Practice", value: "100%" },
        { label: "SEO", value: "83%" },
      ],
      tags: ["Captured at 25. Nov. 2024, 21:06 MEZ by Google PageSpeed Insights"],
      liveUrl: "https://greek-psi.vercel.app/",
    },
{
      title: "Fotostudio",
      subtitle: "React • Tailwind",
      description: "Hohe Peformancewerte, modernes styling und atmospärisches Design.",
      image: photo,
      stats: [
        { label: "Performance", value: "98%" },
        { label: "Accessability", value: "95%" },
        { label: "Best Practice", value: "100%" },
        { label: "SEO", value: "100%" },
      ],
      tags: ["Captured at 25. Nov. 2024, 21:17 MEZ by Google PageSpeed Insights"],
      liveUrl: "https://photo-plum-delta.vercel.app/",
    },

    {
      title: "Musikschule",
      subtitle: "React • D3.js • Firebase",
      description: "Eine stylische und intuitiv gestaltete Seite für eine Musikschule",
      image: musikschule,
      stats: [
        { label: "Performance", value: "99%" },
        { label: "Accessability", value: "100%" },
        { label: "Best Practice", value: "96%" },
        { label: "SEO", value: "90%" },
      ],
      tags: ["Captured at 25. Nov. 2024, 21:07 MEZ by Google PageSpeed Insights"],
      liveUrl: "https://faxfox11.github.io/musikschule/",
    },
    {
      title: "Tech Unternehmen",
      subtitle: "React • React-Dome • Firebase",
      description: "Eine stylische und intuitiv gestaltete Seite für eine Musikschule",
      image: tech,
      stats: [
        { label: "Performance", value: "92%" },
        { label: "Accessability", value: "100%" },
        { label: "Best Practice", value: "96%" },
        { label: "SEO", value: "91%" },
      ],
      tags: ["Captured at 25. Nov. 2024, 21:07 MEZ by Google PageSpeed Insights"],
      liveUrl: "https://tech-faxfox11s-projects.vercel.app/",
    },
  ];

  const handleNavigation = (direction) => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    const newIndex =
      direction === "next"
        ? (currentIndex + 1) % projects.length
        : (currentIndex - 1 + projects.length) % projects.length;
    setCurrentIndex(newIndex);
    setTimeout(() => setIsTransitioning(false), 500);
  };

  return (
    <div className="relative w-full bg-transparent pt-32 pb-20">
      {/* Background Elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div 
          className="absolute inset-0 opacity-40 bg-grid-pattern"
          style={{
            backgroundSize: '30px 30px',
            backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px),
                             linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px)`
          }}
        />
        <div className="absolute top-0 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-4000" />
        <div className="absolute -bottom-40 left-1/4 w-96 h-96 bg-pink-500/20 rounded-full mix-blend-multiply filter blur-3xl animate-blob" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4">
        {/* Section Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-100 to-indigo-100 dark:from-purple-900/50 dark:to-indigo-900/50 px-4 py-2 rounded-full mb-6"
          >
            <Sparkles className="w-5 h-5 text-purple-600 dark:text-purple-400" />
            <span className="text-base font-medium text-purple-900 dark:text-purple-300">
              Aktuelle Projekte
            </span>
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="text-4xl md:text-5xl font-bold mb-6"
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-600 dark:from-white dark:to-gray-300">
              Portfolio
            </span>
            <span className="relative ml-2">
              <span className="relative z-10 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-cyan-500 to-pink-500">
                Showcase
              </span>
              <span className="absolute inset-x-0 bottom-0 h-3 bg-gradient-to-r from-purple-200/30 to-pink-200/30 dark:from-purple-900/30 dark:to-pink-900/30 transform -skew-x-12" />
            </span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.4 }}
            className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto"
          >
            Schau dir gerne ein paar unserer Projekte an. Taten sprechen eben mehr als Worte.
          </motion.p>
        </motion.div>

        {/* Project Showcase */}
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <div className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl rounded-3xl shadow-xl overflow-hidden border border-gray-200/50 dark:border-gray-700/50">
              <div className="grid md:grid-cols-2 gap-0">
                {/* Image Section */}
                <div className="relative h-[400px] md:h-[600px] overflow-hidden">
                  <motion.div
                    initial={{ scale: 1.1 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="absolute inset-0 bg-gradient-to-br from-purple-600/10 to-pink-600/10 mix-blend-overlay"
                  />
                  <motion.img
                    src={projects[currentIndex].image}
                    alt={projects[currentIndex].title}
                    className="w-full h-full object-cover"
                    initial={{ scale: 1.1 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                  />
                </div>

                {/* Content Section */}
                <div className="p-8 lg:p-12">
                  <div className="space-y-8">
                    {/* Title Area */}
                    <div>
                      <motion.p
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.2 }}
                        className="text-purple-600 dark:text-purple-400 font-mono text-base mb-2"
                      >
                        {projects[currentIndex].subtitle}
                      </motion.p>
                      <motion.h3
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.3 }}
                        className="text-3xl md:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-600 dark:from-white dark:to-gray-300"
                      >
                        {projects[currentIndex].title}
                      </motion.h3>
                    </div>

                    {/* Description */}
                    <motion.p
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.4 }}
                      className="text-xl text-gray-600 dark:text-gray-300"
                    >
                      {projects[currentIndex].description}
                    </motion.p>

                    {/* Stats Grid */}
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.5 }}
                      className="grid grid-cols-2 gap-4"
                    >
                      {projects[currentIndex].stats.map((stat, index) => (
                        <div
                          key={index}
                          className="bg-purple-50/50 dark:bg-purple-900/20 backdrop-blur-sm rounded-xl p-4 border border-purple-100 dark:border-purple-800"
                        >
                          <div className="text-2xl md:text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
                            {stat.value}
                          </div>
                          <div className="text-base text-gray-600 dark:text-gray-400">
                            {stat.label}
                          </div>
                        </div>
                      ))}
                    </motion.div>

                    {/* Tags */}
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.6 }}
                      className="flex flex-wrap gap-2"
                    >
                      {projects[currentIndex].tags.map((tag, index) => (
                        <span
                          key={index}
                          className="px-4 py-2 text-base bg-gray-100/80 dark:bg-gray-700/80 backdrop-blur-sm text-gray-800 dark:text-gray-200 rounded-full"
                        >
                          {tag}
                        </span>
                      ))}
                    </motion.div>

                    {/* Action Button */}
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.7 }}
                      className="pt-4"
                    >
                      <a
                        href={projects[currentIndex].liveUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-8 py-4 rounded-xl text-lg font-medium shadow-lg shadow-purple-500/25 hover:shadow-xl hover:shadow-purple-500/30 transition-all duration-300"
                      >
                        <Globe className="w-5 h-5" />
                        <span>View Live</span>
                        <ExternalLink className="w-4 h-4" />
                      </a>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>

            {/* Navigation */}
            <div className="flex justify-center mt-8 gap-4">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleNavigation("prev")}
                disabled={isTransitioning}
                className="p-3 rounded-full bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm shadow-lg hover:shadow-xl border border-gray-200/50 dark:border-gray-700/50 transition-all duration-200"
                aria-label="Previous project"
              >
                <ArrowLeft className="w-6 h-6 text-gray-700 dark:text-gray-300" />
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => handleNavigation("next")}
                disabled={isTransitioning}
                className="p-3 rounded-full bg-white/80 dark:bg-gray-800/80 backdrop-blur-sm shadow-lg hover:shadow-xl border border-gray-200/50 dark:border-gray-700/50 transition-all duration-200"
                aria-label="Next project"
              >
                <ArrowRight className="w-6 h-6 text-gray-700 dark:text-gray-300" />
              </motion.button>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PortfolioShowcase;