import React from "react";

import MobileDevHero from "../components/MobileDevSections/MobileDevHero";
import MobileAppHero from "../components/MobileDevSections/MobileAppHero";
import MobileAppFeatures from "../components/MobileDevSections/MobileAppFeatures";

const mobiledevelopement = () => {
  return (
    <div>
      <MobileAppHero />
      <MobileDevHero />
      <MobileAppFeatures />
    </div>
  );
};

export default mobiledevelopement;
