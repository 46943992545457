import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Smartphone, Code2, Layout, Palette, Zap } from "lucide-react";

const MobileAppFeatures = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Sync with system/document dark mode
  useEffect(() => {
    const isDark = document.documentElement.classList.contains("dark");
    setIsDarkMode(isDark);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          setIsDarkMode(document.documentElement.classList.contains("dark"));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const features = [
    {
      icon: Smartphone,
      title: "Native Technologie",
      description:
        "Wir entwickeln Apps für iOS und Android, die die native Leistungsfähigkeit voll ausschöpfen.",
      gradient: "from-violet-500 to-purple-600",
    },
    {
      icon: Code2,
      title: "Cross-Platform",
      description:
        "Mit Flutter und React Native bieten wir Lösungen, die plattformübergreifend funktionieren.",
      gradient: "from-purple-500 to-blue-600",
    },
    {
      icon: Layout,
      title: "Benutzerfreundlichkeit",
      description:
        "Unser Fokus liegt auf intuitivem Design und flüssiger Interaktion für ein hervorragendes Nutzererlebnis.",
      gradient: "from-blue-500 to-cyan-600",
    },
    {
      icon: Palette,
      title: "Individuelle Gestaltung",
      description:
        "Wir passen jede App an Ihre Marke und Zielgruppen an, um einen einzigartigen Auftritt zu schaffen.",
      gradient: "from-cyan-500 to-teal-600",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  return (
    <div
      className={`relative py-32 ${
        isDarkMode ? "bg-gray-900" : "bg-white"
      } transition-colors duration-300`}
    >
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          className="text-center mb-16"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <motion.div
            variants={itemVariants}
            className="inline-flex items-center px-6 py-2 rounded-full bg-gradient-to-r from-violet-500/10 to-purple-500/10 dark:from-violet-500/20 dark:to-purple-500/20 mb-8"
          >
            <Zap className="w-5 h-5 text-violet-500 mr-2" />
            <span className="text-sm font-medium bg-clip-text text-transparent bg-gradient-to-r from-violet-500 to-purple-500">
              Mobilität, die überzeugt
            </span>
          </motion.div>

          <motion.h2
            variants={itemVariants}
            className={`text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight mb-6 ${
              isDarkMode ? "text-white" : "text-gray-900"
            }`}
          >
            Leistungen, die begeistern
          </motion.h2>

          <motion.p
            variants={itemVariants}
            className={`text-xl ${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            Unsere Expertise in nativer und plattformübergreifender
            Mobil-Entwicklung sichert Ihnen innovative Apps mit
            Alleinstellungsmerkmal.
          </motion.p>
        </motion.div>

        <motion.div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className={`relative group p-8 rounded-2xl ${
                isDarkMode
                  ? "bg-gray-800/50 border-gray-700/50 hover:bg-gray-800/70"
                  : "bg-white/50 border-gray-200 hover:bg-white/70"
              } backdrop-blur-sm border transition-all duration-300 cursor-pointer`}
              whileHover={{ y: -5, scale: 1.02 }}
            >
              <motion.div
                className={`bg-gradient-to-br ${feature.gradient} w-12 h-12 rounded-xl flex items-center justify-center mb-4 mx-auto`}
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.5 }}
              >
                {React.createElement(feature.icon, {
                  className: "w-6 h-6 text-white",
                })}
              </motion.div>
              <h3
                className={`font-semibold text-lg mb-2 ${
                  isDarkMode ? "text-white" : "text-gray-900"
                }`}
              >
                {feature.title}
              </h3>
              <p
                className={`mb-0 ${
                  isDarkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                {feature.description}
              </p>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default MobileAppFeatures;
