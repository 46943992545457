import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import {
  Laptop,
  Smartphone,
  Code2,
  Palette,
  Layout,
  Trophy,
  Zap,
  ArrowRight,
  Monitor,
  Globe2,
} from "lucide-react";

import nextjsLight from '../../assets/nextjs-logo-light.png';
import nextjsDark from '../../assets/nextjs-logo-dark.png';
import vercelLight from '../../assets/vercel-icon-light.png';
import vercelDark from '../../assets/vercel-icon-dark.png';
import reactLogo from '../../assets/react-logo.svg';
import stratoLogo from '../../assets/strato.svg';
import postcss from '../../assets/PostCSS.svg';
import tailwind from '../../assets/tailwind.svg';
import typescript from '../../assets/typescript.svg';
import npmlogo from '../../assets/npm.svg';
import phplogo from '../../assets/PHP.svg';
import postgreslogo from '../../assets/PostgresSQL.svg';
import salesforce from '../../assets/Salesforce.svg';
import vitejs from '../../assets/Vite.js.svg';
import vscode from '../../assets/vscode.svg';
import vuejs from '../../assets/Vue.js.svg';
import wordpress from '../../assets/WordPress.svg';
import Xcode from '../../assets/Xcode.svg';
import Yarn from '../../assets/Yarn.svg';
import CSS3 from '../../assets/CSS3.svg';
import FileZilla from '../../assets/FileZilla.svg';
import github from '../../assets/GitHub.svg';
import html5 from '../../assets/HTML5.svg';
import js from '../../assets/JavaScript.svg';
import json from '../../assets/JSON.svg';




const LogoCarousel = ({ isDarkMode }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const logos = [
    {
      src: isDarkMode ? vercelLight : vercelDark,
      alt: "Vercel",
      isWide: true
    },
    {
      src: npmlogo,
      alt: "NPM",
      isWide: false
    },
    {
      src: isDarkMode ? nextjsLight : nextjsDark,
      alt: "Next.js",
      isWide: true
    },
    {
      src: reactLogo,
      alt: "React",
      isWide: false
    },
    {
      src: stratoLogo,
      alt: "Strato",
      isWide: true
    },
    {
      src: tailwind,
      alt: "Tailwind CSS",
      isWide: false
    },
    {
      src: typescript,
      alt: "TypeScript",
      isWide: false
    },
    {
      src: postcss,
      alt: "PostCSS",
      isWide: false
    },
    {
      src: phplogo,
      alt: "PHP",
      isWide: false
    },
    {
      src: postgreslogo,
      alt: "PostgreSQL",
      isWide: false
    },
    {
      src: salesforce,
      alt: "Salesforce",
      isWide: false
    },
    {
      src: vitejs,
      alt: "Vite.js",
      isWide: false
    },
    {
      src: vscode,
      alt: "VS Code",
      isWide: false
    },
    {
      src: vuejs,
      alt: "Vue.js",
      isWide: false
    },
    {
      src: wordpress,
      alt: "WordPress",
      isWide: false
    },
    {
      src: Xcode,
      alt: "Xcode",
      isWide: false
    },
    {
      src: Yarn,
      alt: "Yarn",
      isWide: false
    },
    {
      src: CSS3,
      alt: "CSS3",
      isWide: false
    },
    {
      src: FileZilla,
      alt: "FileZilla",
      isWide: false
    },
    {
      src: github,
      alt: "GitHub",
      isWide: false
    },
    {
      src: html5,
      alt: "HTML5",
      isWide: false
    },
    {
      src: js,
      alt: "JavaScript",
      isWide: false
    },
    {
      src: json,
      alt: "JSON",
      isWide: false
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === logos.length - 4 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(timer);
  }, [logos.length]);

  return (
    <div className="relative overflow-hidden w-full">
      <motion.div
        className="flex"
        animate={{
          x: `-${currentIndex * 25}%`
        }}
        transition={{
          duration: 0.5,
          ease: "easeInOut"
        }}
      >
        {logos.map((logo, idx) => (
          <motion.div
            key={idx}
            className={`flex-shrink-0 w-1/4 px-4 h-16 flex items-center justify-center`}
            whileHover={{ scale: 1.05 }}
          >
            <div className={`h-full flex items-center justify-center ${
              logo.isWide ? 'w-40' : 'w-16'
            }`}>
              <img
                src={logo.src}
                alt={logo.alt}
                className={`object-contain ${
                  logo.isWide ? 'w-full' : 'h-16'
                }`}
              />
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};



const HeroSection = () => {
  const [hoveredService, setHoveredService] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const navigate = useNavigate();

  // Sync with system/document dark mode
  useEffect(() => {
    const isDark = document.documentElement.classList.contains("dark");
    setIsDarkMode(isDark);

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          setIsDarkMode(document.documentElement.classList.contains("dark"));
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => observer.disconnect();
  }, []);

  const services = [
    {
      icon: Layout,
      title: "Web Design",
      description: "Pixel-perfekte Websites",
      gradient: "from-pink-500 to-rose-600",
      features: ["Custom UI/UX", "Responsive Design", "SEO Optimiert"],
      path: "../Webdevelopement",
    },
    {
      icon: Smartphone,
      title: "Mobile Apps",
      description: "Native & Cross-Platform",
      gradient: "from-violet-500 to-purple-600",
      features: ["iOS & Android", "Flutter & React Native", "App Store Ready"],
      path: "../mobiledevelopement",
    },
    {
      icon: Code2,
      title: "Software",
      description: "Maßgeschneiderte Lösungen",
      gradient: "from-blue-500 to-cyan-600",
      features: ["Cloud Native", "Microservices", "API First"],
      path: "../Softwaredevelopement",
    },
  ];

  const handleServiceClick = (path) => {
    navigate(path);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
      },
    },
  };

  const handleProjectRequest = (e) => {
    e.preventDefault();
    const contactSection = document.getElementById("Contact");
    if (contactSection) {
      const headerOffset = 100;
      const elementPosition = contactSection.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handlePortfolioView = () => {
    navigate("/portfolio");
  };

  return (
    <div
      className={`relative min-h-screen ${
        isDarkMode ? "bg-gray-900" : "bg-gray-50"
      } overflow-hidden transition-colors duration-300`}
    >
      <motion.div
        className="relative max-w-7xl mx-auto px-4 pt-20 pb-32"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={itemVariants} className="text-center mb-16">
          <h1 className="text-4xl sm:text-6xl lg:text-7xl font-bold mb-8 tracking-tight">
            <motion.span
              className={`block ${isDarkMode ? "text-white" : "text-gray-900"}`}
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 100 }}
            >
              Ihre Vision.
            </motion.span>
            <motion.span
              className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-cyan-500"
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 100, delay: 0.2 }}
            >
              Unser Design.
            </motion.span>
          </h1>

          <motion.p
            variants={itemVariants}
            className={`text-xl ${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            } max-w-2xl mx-auto mb-12`}
          >
            Von atemberaubenden Websites über mobile Apps bis hin zu
            individueller Software – wir bringen Ihr digitales Projekt auf das
            nächste Level
          </motion.p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-16">
            {services.map((service, idx) => (
              <motion.div
                key={idx}
                variants={itemVariants}
                onHoverStart={() => setHoveredService(idx)}
                onHoverEnd={() => setHoveredService(null)}
                onClick={() => handleServiceClick(service.path)}
                className={`relative group ${
                  isDarkMode
                    ? "bg-gray-800/50 border-gray-700/50 hover:bg-gray-800/70"
                    : "bg-white/50 border-gray-200 hover:bg-white/70"
                } backdrop-blur-sm border rounded-2xl p-6 transition-all duration-300 cursor-pointer`}
                whileHover={{ y: -5, scale: 1.02 }}
              >
                <motion.div
                  className={`bg-gradient-to-br ${service.gradient} w-12 h-12 rounded-xl flex items-center justify-center mb-4 mx-auto`}
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.5 }}
                >
                  {React.createElement(service.icon, {
                    className: "w-6 h-6 text-white",
                  })}
                </motion.div>
                <h3
                  className={`font-semibold text-lg mb-2 ${
                    isDarkMode ? "text-white" : "text-gray-900"
                  }`}
                >
                  {service.title}
                </h3>
                <p
                  className={`mb-4 ${
                    isDarkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  {service.description}
                </p>
                <AnimatePresence>
                  {hoveredService === idx && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      className="text-sm"
                    >
                      {service.features.map((feature, fidx) => (
                        <motion.div
                          key={fidx}
                          initial={{ x: -20, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{ delay: fidx * 0.1 }}
                          className={`flex items-center gap-2 ${
                            isDarkMode ? "text-gray-300" : "text-gray-600"
                          } mb-1`}
                        >
                          <Zap className="w-4 h-4 text-pink-500" />
                          {feature}
                        </motion.div>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>

          <motion.div
            variants={itemVariants}
            className="flex flex-wrap justify-center gap-4 mb-16"
          >
            <motion.button
              onClick={handleProjectRequest}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-flex items-center px-8 py-4 rounded-xl bg-gradient-to-r from-pink-500 via-purple-500 to-cyan-500 text-white font-medium shadow-lg hover:shadow-xl transition-all duration-300"
            >
              Projekt anfragen
              <ArrowRight className="w-5 h-5 ml-2" />
            </motion.button>
            <motion.button
              onClick={handlePortfolioView}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`inline-flex items-center px-8 py-4 rounded-xl border ${
                isDarkMode
                  ? "border-gray-700 text-gray-300 hover:bg-gray-800/50"
                  : "border-gray-200 text-gray-600 hover:bg-gray-50"
              } font-medium transition-all duration-300`}
            >
              Portfolio ansehen
              <Trophy className="w-5 h-5 ml-2" />
            </motion.button>
          </motion.div>

          <motion.div
            variants={containerVariants}
            className={`max-w-4xl mx-auto py-8 px-6 ${
              isDarkMode
                ? "bg-gray-800/30 border-gray-700/50"
                : "bg-gray-100/30 border-gray-200"
            } backdrop-blur-sm rounded-2xl border`}
          >
            <h4
              className={`mb-6 text-center ${
                isDarkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Wir vertrauen nur den Besten - und das sollten Sie auch
            </h4>
            <LogoCarousel isDarkMode={isDarkMode} />
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default HeroSection;