import React from "react";
import { motion } from "framer-motion";
import { Mail, Phone, MapPin } from "lucide-react";

const KontaktAbschnitt = () => {
  return (
    <div id="Contact" className="bg-gray-100 dark:bg-gray-800 py-20">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8 }}
          className="flex flex-col items-start max-w-2xl mx-auto"
        >
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-4">
            Kontaktieren Sie uns
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
            Haben Sie eine Frage oder möchten Sie ein Projekt besprechen? Wir
            helfen Ihnen gerne weiter.
          </p>
          <div className="space-y-6 w-full">
            <div className="flex items-center space-x-3">
              <Mail className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              <a
                href="mailto:info@tru-tec.de"
                className="text-gray-800 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 font-medium"
              >
                info@tru-tec.de
              </a>
            </div>
            <div className="flex items-center space-x-3">
              <Phone className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              <a
                href="tel:+491707238166"
                className="text-gray-800 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 font-medium"
              >
                +49 170 7238166
              </a>
            </div>
            <div className="flex items-center space-x-3">
              <MapPin className="w-6 h-6 text-blue-600 dark:text-blue-400" />
              <span className="text-gray-800 dark:text-gray-300 font-medium">
                Goldener Spiegel 6, 57074 Siegen
              </span>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default KontaktAbschnitt;